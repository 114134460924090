import React from 'react'

import { Flex, Container, Text, AspectRatio } from '@chakra-ui/react'

const Footer = () => (
  <>
    <AspectRatio
      ratio={140/42}
      backgroundImage="url('/images/ice.svg')"
      backgroundPosition="center"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
    >
      <Container>
        <Flex
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Text>© {new Date().getFullYear()}, onkbear</Text>
        </Flex>
      </Container>
    </AspectRatio>
  </>
)

export default Footer
