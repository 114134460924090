import React from 'react'
import { Link } from 'gatsby'
import { Box, Container, Image, Center } from '@chakra-ui/react'

const NavLink = (props) => (
  <Box
    as={Link}
    display="flex"
    alignItems="center"
    px="3"
    _hover={{
      textDecoration: 'none',
      bg: 'muted',
    }}
    {...props}
  />
)

const Header = () => (
  <Box
    as="header"
    boxShadow="0 2px 0 0 #f5f5f5"
    top="0"
    left="0"
    right="0"
    position="fixed"
    zIndex="30"
    bg="white"
    height="3.25rem"
  >
    <Container maxW="3xl" display="flex" alignItems="center">
      <Box
        alignItems="stretch"
        display="flex"
        justifyContent="center"
        minHeight="3.25rem"
      >
        <Center>
          <Link to="/">
            <Image src="/images/onkbear.svg" h="2rem" alt="onkbear" />
          </Link>
        </Center>
      </Box>
      <Box sx={{ mx: 'auto' }} />
      <Box
        sx={{
          alignItems: 'stretch',
          display: 'flex',
          flexShrink: 0,
          minHeight: '3.25rem',
        }}
      >
        <NavLink to="/">About</NavLink>
      </Box>
    </Container>
  </Box>
)

export default Header
