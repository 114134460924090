/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { Location } from '@reach/router'

type Props = {
  title: string
  lang?: string
  meta?: { name: string; content: string }[]
  description?: string
  imageUrl?: string
}

function SEO({ description, lang, meta, title, imageUrl }: Props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            author
          }
        }
      }
    `
  )

  const fullURL = (path: string) =>
    path ? `${path}` : site.siteMetadata.siteUrl

  // If no image is provided lets looks for a default novela static image
  imageUrl = imageUrl
    ? imageUrl
    : `${site.siteMetadata.siteUrl}/images/onkbear.png`

  // Checks if the source of the image is hosted on Contentful
  if (`${imageUrl}`.includes('ctfassets')) {
    imageUrl = `https:${imageUrl}`
  } else {
    imageUrl = fullURL(imageUrl)
  }

  const metaDescription = description || site.siteMetadata.description
  const ogTitle = `${title} | ${site.siteMetadata.title}`

  return (
    <Location>
      {({ location }) => {
        return (
          <Helmet
            encodeSpecialCharacters={false}
            htmlAttributes={{
              lang,
              class: 'has-navbar-fixed-top',
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: ogTitle,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:site_name`,
                content: site.siteMetadata.title,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:url`,
                content: location.href || site.siteMetadata.siteUrl,
              },
              {
                property: `og:image`,
                content: imageUrl || ``,
              },
              {
                name: `twitter:image`,
                content: imageUrl || ``,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:site`,
                content: `onkbear`,
              },
              {
                name: `twitter:title`,
                content: ogTitle,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
            ].concat(meta)}
          />
        )
      }}
    </Location>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
