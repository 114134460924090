/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import { ChakraProvider, extendTheme, Box } from '@chakra-ui/react'

import Header from './Header'
import Footer from './Footer'

const theme = extendTheme({
  global: {
    'html, body': {
      color: '#4a4a4a',
    },
    a: {
      color: 'teal.500',
      _hover: {
        textDecoration: 'underline',
      },
    },
  },
  fonts: {
    body: 'Saira Semi Condensed, sans-serif',
    heading: 'Saira Semi Condensed, sans-serif',
    mono: 'Menlo, monospace',
  },
  colors: {
    primary: '#002440',
    secondary: '#9daac7',
    sky: '#eff8ff',
  },
})

type Props = {
  children: React.ReactNode
}

const Layout = ({ children }: Props) => {
  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Box as="main" mt="3.25rem" mb={4}>
        {children}
      </Box>
      <Footer />
    </ChakraProvider>
  )
}

export default Layout
